import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import Script from "next/script";
import { useEffect, useState } from "react";
import { api } from "~/utils/api";

const USER_NAME_CONVERSATION_FIELD_ID = "25337234417681";
const USER_EMAIL_CONVERSATION_FIELD_ID = "25337238431377";

const ZENDESK_CHAT_BLACKLISTED_PAGES_CONFIG = [
  {
    id: "screening-pages",
    label: "url-coontains-the-param",
    urls: [
      "/full-text/",
      "article-reviews/",
      "/title-abstract/",
      "/review-duplicates/review",
      "/data-extraction/",
      "/welcome/",
    ],
    checker(routerPathname: string) {
      return function (k: string) {
        return routerPathname.includes(k);
      };
    },
  },
  {
    id: "public-individual-page",
    label: "url-strictly-equal-to-the-param",
    urls: ["/", "/about-us", "terms", "privacy"],
    checker(routerPathname: string) {
      return function (k: string) {
        return routerPathname === k;
      };
    },
  },
  {
    id: "public-dynamic-pages",
    label: "url-starts-with-param",
    urls: ["/blog"],
    checker(routerPathname: string) {
      return function (k: string) {
        return routerPathname.startsWith(k);
      };
    },
  },
];

const ZendeskWidget = () => {
  const [isLoadedScript, setIsLoadedScript] = useState(false);
  const [isLoggedInZendesk, setIsLoggedInZendesk] = useState(false);

  const { data: sessionData, status } = useSession();
  const router = useRouter();

  const isAuthenticatedUser = !!sessionData?.user && status === "authenticated";
  const user = sessionData?.user;

  const { data: zendeskToken } = api.zendesk.getToken.useQuery(undefined, {
    enabled: !isLoggedInZendesk && !!sessionData && !!sessionData.user.name,
  });

  const routerPathname = router.pathname;

  const hideZendeskWidget = ZENDESK_CHAT_BLACKLISTED_PAGES_CONFIG.some((k) => {
    return k.urls.some((m) => k.checker(routerPathname)(m));
  });

  useEffect(() => {
    if (hideZendeskWidget) {
      hideWidget();
    } else {
      showWidget();
    }
  }, [hideZendeskWidget, isLoggedInZendesk]);

  function hideWidget() {
    if (window.zE) {
      window.zE("messenger", "hide");
      window.zE("messenger", "close");
    }
  }

  function showWidget() {
    if (window.zE) {
      window.zE("messenger", "show");
    }
  }

  function logoutZendeskUser() {
    if (window && window.zE) {
      window.zE("messenger", "logoutUser");
    }
  }

  useEffect(
    function handleZendeskLogin() {
      function identifyUser(token: string) {
        if (window.zE) {
          window.zE(
            "messenger",
            "loginUser",
            function (callback: (x: string) => void) {
              if (token) {
                callback(token);
              }
            },
          );

          window.zE("messenger:set", "zIndex", 1);

          window.zE("messenger:set", "conversationFields", [
            {
              id: USER_NAME_CONVERSATION_FIELD_ID,
              value: `${user?.name ?? ""}_${user?.id ?? ""}`,
            },
          ]);

          window.zE("messenger:set", "conversationFields", [
            { id: USER_EMAIL_CONVERSATION_FIELD_ID, value: user?.email ?? "" },
          ]);
        }

        // const launcher = document.querySelector("#launcher");
        // if (launcher) {
        //   launcher.style.width = "50px";
        //   launcher.style.height = "50px";
        // }
      }

      if (isLoadedScript && isAuthenticatedUser && user?.name) {
        if (!isLoggedInZendesk && !!zendeskToken) {
          identifyUser(zendeskToken);
          setIsLoggedInZendesk(true);
        }
      }
    },
    [
      isLoadedScript,
      isAuthenticatedUser,
      zendeskToken,
      isLoggedInZendesk,
      user,
    ],
  );

  useEffect(() => {
    // to handle logout of zendesk chat whenever user is unauthenticated
    if (!isAuthenticatedUser) {
      if (isLoadedScript) {
        hideWidget();
        logoutZendeskUser();
        setIsLoggedInZendesk(false);
      }
    }
  }, [isAuthenticatedUser, isLoadedScript]);

  useEffect(() => {
    // to handle logout of zendesk chat upon unmounting
    return () => {
      if (isLoggedInZendesk) {
        hideWidget();
        logoutZendeskUser();
        setIsLoggedInZendesk(false);
      }
    };
  }, [isLoggedInZendesk]);

  return (
    <Script
      id="ze-snippet"
      src="https://static.zdassets.com/ekr/snippet.js?key=965b8c3a-c8b2-4554-b7f7-c2c1a1496514"
      onLoad={() => {
        setIsLoadedScript(true);
      }}
    />
  );
};

export default ZendeskWidget;
