import { cva, type VariantProps } from "class-variance-authority";
import { type ExternalToast, toast as sonnerToast, Toaster } from "sonner";
import { cn } from "~/lib/utils";

const toastVariants = cva("class", {
  variants: {
    variant: {
      default:
        "border bg-background text-foreground border-0 group-hover:border-1",
      destructive:
        "destructive group border-destructive bg-destructive text-destructive-foreground group-hover:bg-destructive",
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

interface ToastProps extends ExternalToast {
  title: string | React.ReactNode;
  variant?: "default" | "destructive";
}

function toast({
  title,
  position = "bottom-left",
  variant = "default",
  ...props
}: ToastProps) {
  return sonnerToast(title ?? "", {
    position,
    className: cn(toastVariants({ variant })),
    classNames: {
      // default: cn(toastVariants({ variant: variant })),
      // error:
      //   "destructive group border-destructive bg-destructive bg-red-500 text-destructive-foreground",
      closeButton: cn("absolute top-4 left-[94%]", toastVariants({ variant })),
      ...(props.icon ? {} : { icon: "w-0" }),
    },
    icon: props.icon ?? <></>,
    closeButton: true,
    ...props,
  });
}

function SonnerToaster() {
  return <Toaster />;
}

export { toast, SonnerToaster as Toaster };
